.IconBecomeCustomer {
    position: relative;
    
    &__Wrapper {
        position: absolute;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}
